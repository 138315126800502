/* eslint-disable array-callback-return */
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {useSelector} from 'react-redux';
import { Loader} from '@crosslud/components';
import useActivity from '@crosslud/hooks/useActivity';
import useActiveLink from '@crosslud/hooks/useActiveLink';
import { useTranslation } from 'react-i18next';
import { CommonAPI } from '@crosslud/apis/CommonAPI';

const AccountDelete = () => {
  // SET ACTIVELINK
  useActiveLink('accountLayout', 'delete');

  const { t } = useTranslation();
  const activityHook = useActivity(false);
  const navigate = useNavigate();

  const auth = useSelector(state => {
    return state.auth;
  });

  const sendVerificationCode = async () => {
    var activityRef = activityHook.start();

    const response = await CommonAPI.userAccountDeleteCode({email: auth?.user?.email});

    if (response?.status) {
      navigate('/account/delete/verification', {state: {
        email: response?.payload?.email,
        scope: response?.payload?.scope
      }});
    }

    activityHook.stop(activityRef);
  };

  return (
    activityHook.isLoading ? <Loader /> :
    <section className="bg-black-1 pd-30 ht-100p">
      <h3 className="tx-center pd-b-10">{t('me.screen.account.deletion')}</h3>
      <div className="mg-t-60">
        <h6 className="tx-13 tx-normal">
          <div dangerouslySetInnerHTML={{ __html: t('fe.account.deletion.aware.description.html') }} />
        </h6>
        <div className="pd-y-20">
          <button className="btn btn-block btn-info tx-uppercase tx-11 tx-spacing-2" onClick={() => sendVerificationCode()}>
            {t('fe.account.deletion.send.code.button')}
          </button>
        </div>
      </div>
    </section>
  )
}

export default AccountDelete;