/* eslint-disable array-callback-return */
import React, {useState} from 'react';
import {useLocation} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {userLogout} from '@crosslud/redux/Auth/auth-actions';
import { Loader} from '@crosslud/components';
import useActivity from '@crosslud/hooks/useActivity';
import useActiveLink from '@crosslud/hooks/useActiveLink';
import { useTranslation } from 'react-i18next';
import Alert from '@crosslud/helpers/Alert';
import { UserAPI } from '@crosslud/apis/UserAPI';
import { CommonAPI } from '@crosslud/apis/CommonAPI';

const AccountDeleteValidation = () => {
  // SET ACTIVELINK
  useActiveLink('accountLayout', 'delete');

  const { t } = useTranslation();
  const activityHook = useActivity(false);
  //const {token} = useLocalSearchParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const [violations, setViolations] = useState([]);

  const auth = useSelector(state => {
    return state.auth;
  });

  const validation = async () => {
    var activityRef = activityHook.start();

    // Delete account
    const deleteResponse = await UserAPI.accountDelete(auth?.user?.uuid, {token: location?.state?.token});

    // Whenever we have a status
    if (deleteResponse.status || deleteResponse.status === false) {

      // Try to retrieve user info
      const response = await UserAPI.getInfo();

      // If not status then disconnect user from current session
      if (!response.status) {
        const logoutResponse = await CommonAPI.logout();

        if (logoutResponse.status) {
          Alert.notify(
            t('fe.account.delete.alert.success.title'),
            t('fe.account.delete.alert.success.description'),
            'success',
          );

          dispatch(userLogout());

          window.location.href = '/';
        }
      }
    }

    // Violations
    setViolations(deleteResponse?.violations);

    activityHook.stop(activityRef);
  };

  return (
    activityHook.isLoading ? <Loader /> :
    <section className="bg-black-1 pd-30 ht-100p">
      <h3 className="tx-center pd-b-10">{t('me.screen.account.deletion')}</h3>
      <div className="mg-t-60">
        <h6 className="tx-13 tx-normal">
          <div dangerouslySetInnerHTML={{ __html: t('fe.account.deletion.aware.confirmation.description.html') }} />
        </h6>
        <div className="pd-y-20">
          <button className="btn btn-block btn-info tx-uppercase tx-11 tx-spacing-2" onClick={() => validation()}>
            {t('fe.account.deletion.validation.button')}
          </button>
        </div>
      </div>
    </section>
  )
}

export default AccountDeleteValidation;