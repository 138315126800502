import { api } from "./configs/fetchConfig";
import {fetchUtils} from './configs/fecthUtils';
import { activationCodeSchema, commonSearchSchema } from "./configs/schema";

export const CommonAPI = {
  /**
   * 
   * @param {path = '/api/categories/2/genders', searchBy = ['name', 'reference'], searchValue = null, page = 1} param0 
   * @returns 
   */
  callApi: async ({path, searchBy = [], searchValue = null, page = 1}) => {
    var filters = [];

    searchBy?.map(key => {
      return filters[key] = searchValue;
    });
    
    let query = fetchUtils
      .customQueryFilterFormat(filters, commonSearchSchema)
      .join('&');
    
    let q = '?page=' + page + (query && '&' + query);

    var url = path + '.jsonld' + q;
    
    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource(url, {
      method: 'GET',
      headers: headers,
    });
  },
  /**
   * Login
   * @param {*} email 
   * @param {*} password 
   * @returns 
   */
  login: async (email = '', password = '') => {
    return await api.getToken(email, password);
  },
  /**
   * Logout
   * @returns
   */
  logout: async() => {
    return await api.unsetToken();
  },
  /**
   * activation code token
   * @param {*} data 
   * @returns 
   */
  activationCodeToken: async data => {
    return await api.fetchResource('/api/activation-code/token', {
      method: 'POST',
      body: JSON.stringify(data),
    });
  },
  /**
   * user forgotten password code
   * @param {*} data 
   * @returns 
   */
  userAccountDeleteCode: async data => {
    return await api.fetchResource('/api/users/account-delete/code', {
      method: 'POST',
      body: JSON.stringify(data),
    });
  },
  /**
   * Get infoFeeds
   * @returns 
   */
  getInfoFeeds: async () => {
    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource('/api/info-feeds.jsonld', {
      method: 'GET',
      headers: headers,
    });
  },
  /**
   * Get infoFeed
   * @param {*} id 
   * @returns 
   */
  getInfoFeed: async (id) => {
    var headers = new Headers();
    headers.set('Accept', 'application/json');

    return await api.fetchResource('/api/info-feeds/' + id + '.json', {
      method: 'GET',
      headers: headers,
    });
  },
  /**
   * Get companies
   * @param {*} category 
   * @param {*} filters 
   * @param {*} page 
   * @returns 
   */
  getCompanies: async (category, filters = [], page = 1) => {
    let query = fetchUtils
      .customQueryFilterFormat(filters, commonSearchSchema)
      .join('&');
    
    let q = '?page=' + page + (query && '&' + query);

    var url = null;

    if (category) {
      url = '/api/categories/' + category + '/companies.jsonld' + q;
    } else {
      url = '/api/item-companies.jsonld' + q;
    }

    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource(url, {
      method: 'GET',
      headers: headers,
    });
  },
  getSupports: async (filters = [], page = 1) => {
    let query = fetchUtils
      .customQueryFilterFormat(filters, commonSearchSchema)
      .join('&');
      
    let q = '?pagination=true&page=' + page + (query && '&' + query);

    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource('/api/supports.jsonld' + q, {
      method: 'GET',
      headers: headers,
    });
  },
  getSupportCodes: async (supportId, filters = [], page = 1) => {
    let query = fetchUtils
      .customQueryFilterFormat(filters, commonSearchSchema)
      .join('&');
      
    let q = '?page=' + page + (query && '&' + query);

    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource('/api/supports/' + supportId + '/item-support-codes.jsonld' + q, {
      method: 'GET',
      headers: headers,
    });
  },
  getCurrencies: async (filters = [], page = 1) => {
    const newFilters = filters.concat('page=' + page);

    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource('/api/currencies.jsonld?' + newFilters.join('&'), {
      method: 'GET',
      headers: headers,
    });
  },
  getItemStatuses: async (filters = [], page = 1) => {
    let q = '?page=' + page + '&' + filters.join('&');

    var url = '/api/item-statuses.jsonld' + q;

    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource(url, {
      method: 'GET',
      headers: headers,
    });
  },
  getProtectedScanStatuses: async (filters = [], page = 1) => {
    let q = '?page=' + page + '&' + filters.join('&');

    var url = '/api/protected/scan-statuses.jsonld' + q;

    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource(url, {
      method: 'GET',
      headers: headers,
    });
  },
  getProtectAdminUsers: async (filters = [], page = 1) => {
    const newFilters = filters.concat('page=' + page);

    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource('/api/protected-admin-users.jsonld?' + newFilters.join('&'), {
      method: 'GET',
      headers: headers,
    });
  },
  getCivilities: async (filters = [], page = 1) => {
    const newFilters = filters.concat('page=' + page);

    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');
    
    return await api.fetchResource('/api/civilities.jsonld?' + newFilters.join('&'), {
      method: 'GET',
      headers: headers,
    });
  },
  getCountries: async (filters = [], page = 1) => {
    const newFilters = filters.concat('page=' + page);

    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');
    
    return await api.fetchResource('/api/countries.jsonld?' + newFilters.join('&'), {
      method: 'GET',
      headers: headers,
    });
  },
  getTranslations: async (locale, domain = null) => {
    return await api.fetchResource(`/api/i18n/${locale}${domain ? '/' + domain : ''}.json`, {
      method: 'GET',
    });
  },
  getProtectedfieldGroups: async (filters = [], page = 1) => {
    const newFilters = filters.concat('page=' + page);

    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');
    
    return await api.fetchResource('/api/protected/field-groups.jsonld?' + newFilters.join('&'), {
      method: 'GET',
      headers: headers,
    });
  },  
  getBrands: async (category, filters = [], page = 1) => {
    let query = fetchUtils
      .customQueryFilterFormat(filters, commonSearchSchema)
      .join('&');
    
    let q = '?page=' + page + (query && '&' + query);

    var url = null;

    if (category) {
      url = '/api/categories/' + category + '/brands.jsonld' + q;
    } else {
      url = '/api/item-brands.jsonld' + q;
    }

    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource(url, {
      method: 'GET',
      headers: headers,
    });
  },
  getEditions: async (category, filters = [], page = 1) => {
    let query = fetchUtils
      .customQueryFilterFormat(filters, commonSearchSchema)
      .join('&');
    
    let q = '?page=' + page + (query && '&' + query);

    var url = null;

    if (category) {
      url = '/api/categories/' + category + '/editions.jsonld' + q;
    } else {
      url = '/api/item-editions.jsonld' + q;
    }

    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource(url, {
      method: 'GET',
      headers: headers,
    });
  },
  getFormats: async (category, filters = [], page = 1) => {
    let query = fetchUtils
      .customQueryFilterFormat(filters, commonSearchSchema)
      .join('&');
    
    let q = '?page=' + page + (query && '&' + query);

    var url = null;

    if (category) {
      url = '/api/categories/' + category + '/formats.jsonld' + q;
    } else {
      url = '/api/item-formats.jsonld' + q;
    }

    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource(url, {
      method: 'GET',
      headers: headers,
    });
  },
  getGenders: async (category, filters = [], page = 1) => {
    let query = fetchUtils
      .customQueryFilterFormat(filters, commonSearchSchema)
      .join('&');
    
    let q = '?page=' + page + (query && '&' + query);

    var url = null;

    if (category) {
      url = '/api/categories/' + category + '/genders.jsonld' + q;
    } else {
      url = '/api/item-genders.jsonld' + q;
    }

    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource(url, {
      method: 'GET',
      headers: headers,
    });
  },
  getBoxes: async (category, filters = [], page = 1) => {
    let query = fetchUtils
      .customQueryFilterFormat(filters, commonSearchSchema)
      .join('&');
    
    let q = '?page=' + page + (query && '&' + query);

    var url = null;

    if (category) {
      url = '/api/categories/' + category + '/boxes.jsonld' + q;
    } else {
      url = '/api/item-boxes.jsonld' + q;
    }

    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource(url, {
      method: 'GET',
      headers: headers,
    });
  },
  getRanges: async (category, filters = [], page = 1) => {
    let query = fetchUtils
      .customQueryFilterFormat(filters, commonSearchSchema)
      .join('&');
    
    let q = '?page=' + page + (query && '&' + query);

    var url = null;

    if (category) {
      url = '/api/categories/' + category + '/ranges.jsonld' + q;
    } else {
      url = '/api/item-ranges.jsonld' + q;
    }

    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource(url, {
      method: 'GET',
      headers: headers,
    });
  },
  getContents: async (category, filters = [], page = 1) => {
    let query = fetchUtils
      .customQueryFilterFormat(filters, commonSearchSchema)
      .join('&');
    
    let q = '?page=' + page + (query && '&' + query);

    var url = null;

    if (category) {
      url = '/api/categories/' + category + '/contents.jsonld' + q;
    } else {
      url = '/api/item-contents.jsonld' + q;
    }

    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource(url, {
      method: 'GET',
      headers: headers,
    });
  },
  getLanguages: async (category, filters = [], page = 1) => {
    let query = fetchUtils
      .customQueryFilterFormat(filters, commonSearchSchema)
      .join('&');
    
    let q = '?page=' + page + (query && '&' + query);

    var url = null;

    if (category) {
      url = '/api/categories/' + category + '/languages.jsonld' + q;
    } else {
      url = '/api/item-languages.jsonld' + q;
    }

    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource(url, {
      method: 'GET',
      headers: headers,
    });
  },
  getZones: async (category, filters = [], page = 1) => {
    let query = fetchUtils
      .customQueryFilterFormat(filters, commonSearchSchema)
      .join('&');
    
    let q = '?page=' + page + (query && '&' + query);

    var url = null;

    if (category) {
      url = '/api/categories/' + category + '/zones.jsonld' + q;
    } else {
      url = '/api/item-zones.jsonld' + q;
    }
    
    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource(url, {
      method: 'GET',
      headers: headers,
    });
  },
  getLines: async (category, filters = [], page = 1) => {
    let query = fetchUtils
      .customQueryFilterFormat(filters, commonSearchSchema)
      .join('&');
    
    let q = '?page=' + page + (query && '&' + query);

    var url = null;

    if (category) {
      url = '/api/categories/' + category + '/lines.jsonld' + q;
    } else {
      url = '/api/item-lines.jsonld' + q;
    }
    
    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource(url, {
      method: 'GET',
      headers: headers,
    });
  },
  getTypes: async (category, filters = [], page = 1) => {
    let query = fetchUtils
      .customQueryFilterFormat(filters, commonSearchSchema)
      .join('&');
    
    let q = '?page=' + page + (query && '&' + query);

    var url = null;

    if (category) {
      url = '/api/categories/' + category + '/types.jsonld' + q;
    } else {
      url = '/api/item-types.jsonld' + q;
    }
    
    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource(url, {
      method: 'GET',
      headers: headers,
    });
  },
  getMaterials: async (category, filters = [], page = 1) => {
    let query = fetchUtils
      .customQueryFilterFormat(filters, commonSearchSchema)
      .join('&');
    
    let q = '?page=' + page + (query && '&' + query);

    var url = null;

    if (category) {
      url = '/api/categories/' + category + '/materials.jsonld' + q;
    } else {
      url = '/api/item-materials.jsonld' + q;
    }
    
    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource(url, {
      method: 'GET',
      headers: headers,
    });
  },
  getMechanisms: async (category, filters = [], page = 1) => {
    let query = fetchUtils
      .customQueryFilterFormat(filters, commonSearchSchema)
      .join('&');
    
    let q = '?page=' + page + (query && '&' + query);

    var url = null;

    if (category) {
      url = '/api/categories/' + category + '/mechanisms.jsonld' + q;
    } else {
      url = '/api/item-mechanisms.jsonld' + q;
    }
    
    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource(url, {
      method: 'GET',
      headers: headers,
    });
  },
  getNumbers: async (category, filters = [], page = 1) => {
    let query = fetchUtils
      .customQueryFilterFormat(filters, commonSearchSchema)
      .join('&');
    
    let q = '?page=' + page + (query && '&' + query);

    var url = null;

    if (category) {
      url = '/api/categories/' + category + '/numbers.jsonld' + q;
    } else {
      url = '/api/item-numbers.jsonld' + q;
    }
    
    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource(url, {
      method: 'GET',
      headers: headers,
    });
  },
  getPegis: async (category, filters = [], page = 1) => {
    let query = fetchUtils
      .customQueryFilterFormat(filters, commonSearchSchema)
      .join('&');
    
    let q = '?page=' + page + (query && '&' + query);

    var url = null;

    if (category) {
      url = '/api/categories/' + category + '/pegis.jsonld' + q;
    } else {
      url = '/api/item-pegis.jsonld' + q;
    }
    
    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource(url, {
      method: 'GET',
      headers: headers,
    });
  },
  getXContainers: async (category, filters = [], page = 1) => {
    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource(
      '/api/categories/' +
        category +
        '/x-containers.jsonld?page=' +
        page +
        '&' +
        filters.join('&'),
      {
        method: 'GET',
        headers: headers,
      },
    );
  },
  getXContainersFields: async (x_container_id, filters = [], page = 1) => {
    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource(
      '/api/category-x-item-containers/' +
        x_container_id +
        '/fields.jsonld?page=' +
        page +
        '&' +
        filters.join('&'),
      {
        method: 'GET',
        headers: headers,
      },
    );
  },
  getSearchFormFields: async (category, group) => {
    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    var url = '/api/categories/' + category + '/field-group/' + group + '/search-form-fields.jsonld';

    if (!category) {
      url = '/api/field-groups/' + group + '/search-form-fields.jsonld';
    }

    return await api.fetchResource(url, {
      method: 'GET',
      headers: headers,
    });
  },
  getSearchSortFields: async (category, group) => {
    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    var url = '/api/categories/' + category + '/field-group/' + group + '/search-sort-fields.jsonld';

    if (!category) {
      url = '/api/field-groups/' + group + '/search-sort-fields.jsonld';
    }

    return await api.fetchResource(url, {
      method: 'GET',
      headers: headers,
    });
  },
  getProtectedSearchFormFields: async (category, group) => {
    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource(
      '/api/categories/' + category + '/field-group/' + group + '/protected-search-form-fields.jsonld',
      {
        method: 'GET',
        headers: headers,
      },
    );
  },
  getProtectedSearchSortFields: async (category, group) => {
    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');
    
    return await api.fetchResource(
        '/api/categories/' + category + '/field-group/' + group + '/protected-search-sort-fields.jsonld',
      {
        method: 'GET',
        headers: headers,
      },
    );
  },
  bachUpdateProtectedSearchFormFields: async data => {
    return await api.fetchResource(
      '/api/category-x-search-form-fields/batch/protected-update',
      {
        method: 'POST',
        body: JSON.stringify({
          searchFormFields: data,
        }),
      },
    );
  },
  bachUpdateProtectedSearchSortFields: async data => {
    return await api.fetchResource(
      '/api/category-x-search-sort-fields/batch/protected-update',
      {
        method: 'POST',
        body: JSON.stringify({
          searchSortFields: data,
        }),
      },
    );
  },
  getProtectedXContainers: async (category, filters = [], page = 1) => {
    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource(
      '/api/categories/' +
        category +
        '/protected-x-containers.jsonld?page=' +
        page +
        '&' +
        filters.join('&'),
      {
        method: 'GET',
        headers: headers,
      },
    );
  },
  getProtectedXContainer: async (
    category_container_id,
    filters = [],
    page = 1,
  ) => {
    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource(
      '/api/category-x-item-containers/' +
        category_container_id +
        '.jsonld?page=' +
        page +
        '&' +
        filters.join('&'),
      {
        method: 'GET',
        headers: headers,
      },
    );
  },
  getProtectedXContainersFields: async (
    x_container_id,
    filters = [],
    page = 1,
  ) => {
    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource(
      '/api/category-x-item-containers/' +
        x_container_id +
        '/protected-fields.jsonld?page=' +
        page +
        '&' +
        filters.join('&'),
      {
        method: 'GET',
        headers: headers,
      },
    );
  },
  bachUpdateProtectedContainerXFields: async data => {
   return await api.fetchResource(
      '/api/category-container-x-form-fields/batch/protected-update',
      {
        method: 'POST',
        body: JSON.stringify({
          xFields: data,
        }),
      },
    );
  },
  bachUpdateProtectedXContainers: async data => {
    return await api.fetchResource(
      '/api/category-x-item-containers/batch/protected-update',
      {
        method: 'POST',
        body: JSON.stringify({
          xContainers: data,
        }),
      },
    );
  },
  getProtectedCategoryContainerAbilities: async (filters = [], page = 1) => {
    const newFilters = filters.concat('page=' + page);

    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');
    
    return await api.fetchResource('/api/protected/category-container-abilities.jsonld?' + newFilters.join('&'), {
      method: 'GET',
      headers: headers,
    });
  },
}
