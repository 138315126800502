/* eslint-disable array-callback-return */
import React, {useState} from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import { CText, FormInputText, Loader, ProtectedScreen, View } from '@crosslud/components';
import useActivity from '@crosslud/hooks/useActivity';
import useActiveLink from '@crosslud/hooks/useActiveLink';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { CommonAPI } from '@crosslud/apis/CommonAPI';

const AccountDeleteVerification = () => {
  // SET ACTIVELINK
  useActiveLink('accountLayout', 'delete');

  const {t} = useTranslation();
  //const navigation = useNavigation();
  const navigate = useNavigate();
  //const {email,scope} = useLocalSearchParams();
  const location = useLocation();
  const activityHook = useActivity(false);
  const [violations, setViolations] = useState([]);

  // FORM
  const {
    control,
    getValues,
    handleSubmit,
  } = useForm({
    defaultValues: {
      email: location?.state?.email,
      scope: location?.state?.scope,
      code: "",
    }
  });

  const onSubmit = async formData => {
    var activityRef = activityHook.start();

    const response = await CommonAPI.activationCodeToken(formData);

    if (response?.status) {
      navigate('/account/delete/validation', {state: {
        email: location?.state?.email,
        token: response?.payload?.token,
      }});
    }

    // Violations
    setViolations(response?.violations);

    activityHook.stop(activityRef);
  };

  return (
    activityHook.isLoading ? <Loader /> :
    <ProtectedScreen deniedWhen={!getValues().email}>
      <section className="bg-black-1 pd-30 ht-100p">
        <h3 className="tx-center pd-b-10">{t('me.screen.account.deletion')}</h3>
        <div className="mg-t-60">
          <div className="row no-gutters" /* flex-row-reverse */>
            <div className="offset-md-3 col-md-6 bg-br-primary d-flex align-items-center justify-content-center">
              <form>
                <div className="login-wrapper wd-250 wd-xl-350 mg-y-30">
                  <CText className="tx-center mg-b-40 tx-warning">
                    {t('fe.authentication.verification.code.sent.message', {
                      email: getValues().email,
                      codeLength: 8
                    })}
                  </CText>
                  {violations?.error?.message &&
                    <View className='d-flex justify-content-center mg-t-20'>
                      <CText className='text-danger'>
                        {violations?.error?.message}
                      </CText>
                    </View>
                  }
                  <div className="form-group mg-t-10">
                    <Controller
                      name="code"
                      control={control}
                      render={({ field }) => (
                        <FormInputText
                          {...field}
                          className="form-control form-control-dark"
                          placeholder={t('fe.authentication.verification.form.code.placeholder')}
                          violation={violations?.code}
                        />
                      )}
                    />
                  </div>
                  <button
                    type="submit" 
                    className="btn btn-info btn-block"
                    onClick={handleSubmit(onSubmit)}>
                      {t('fe.authentication.verification.code.button')}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </ProtectedScreen>
  )
}

export default AccountDeleteVerification;