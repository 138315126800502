import { api } from "./configs/fetchConfig";
import { fetchUtils } from "./configs/fecthUtils";
import { passwordUpdateSchema, userRegistrationPasswordSchema, userSchema, avatarUpdateSchema } from "./configs/schema";

export const UserAPI = {
  /**
   * Get current-user info
   * @returns
   */
  getInfo: async () => {
    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource('/api/userinfo.jsonld', {
      method: 'GET',
      headers: headers,
    });
  },
  /**
   * Get user
   * @param {*} id 
   * @returns 
   */
  get: async id => {
    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource('/api/users/' + id + '.jsonld', {
      method: 'GET',
      headers: headers,
    });
  },
  /**
   * user registration request
   * @param {*} data 
   * @returns 
   */
  registrationRequest: async data => {
    return await api.fetchResource('/api/users/registration-request', {
      method: 'POST',
      body: JSON.stringify(fetchUtils.bodyFormat(data, userSchema)),
    });
  },
  /**
   * user registration
   * @param {*} data 
   * @returns 
   */
  registration: async data => {
    return await api.fetchResource('/api/users/registration', {
      method: 'POST',
      body: JSON.stringify(fetchUtils.bodyFormat(data, userRegistrationPasswordSchema)),
    });
  },
  /**
   * Forgotten password
   * @param {*} data 
   * @returns 
   */
  forgottenPassword: async data => {
    return await api.fetchResource('/api/users/forgotten-password-request', {
      method: 'POST',
      body: JSON.stringify(fetchUtils.bodyFormat(data, userSchema)),
    });
  },
  changePassword: async data => {
    return await api.fetchResource('/api/users/change-password', {
      method: 'POST',
      body: JSON.stringify(fetchUtils.bodyFormat(data, userRegistrationPasswordSchema)),
    });
  },
  /**
   * Update user nickname
   * @param {*} id 
   * @param {*} data 
   * @returns 
   */
  updateNickname: async (id, data) => {
    return await api.fetchResource('/api/users/' + id + '/nickname/update', {
      method: 'PUT',
      body: JSON.stringify(data),
    });
  },
  /**
   * Update user email
   * @param {*} id 
   * @param {*} data 
   * @returns 
   */
  updateEmail: async (id, data) => {
    return await api.fetchResource('/api/users/' + id + '/email/update', {
      method: 'PUT',
      body: JSON.stringify(data),
    });
  },
  /**
   * Update user password
   * @param {*} id 
   * @param {*} data 
   * @returns 
   */
  updatePassword: async (id, data) => {
    return await api.fetchResource('/api/users/' + id + '/password/update', {
      method: 'PUT',
      body: JSON.stringify(fetchUtils.bodyFormat(data, passwordUpdateSchema)),
    });
  },
  /**
   * Update user avatar
   * @param {*} id 
   * @param {*} data 
   * @returns 
   */
  updateAvatar: async (id, data) => {
    return await api.fetchResource('/api/users/' + id + '/avatar/update', {
      method: 'PUT',
      body: JSON.stringify(fetchUtils.bodyFormat(data, avatarUpdateSchema)),
    });
  },
  /**
   * Update user info
   * @param {*} id 
   * @param {*} data 
   * @returns 
   */
  updateInfo: async (id, data) => {
    return await api.fetchResource('/api/users/' + id + '/info/update', {
      method: 'PUT',
      body: JSON.stringify(fetchUtils.bodyFormat(data, userSchema)),
    });
  },
  /**
   * Account Delete
   * @param {*} id 
   * @param {*} data 
   * @returns 
   */
  accountDelete: async (id, data) => {
    return await api.fetchResource('/api/users/' + id + '/account/delete', {
      method: 'PUT',
      body: JSON.stringify(data),
    });
  },
  /**
   * Get item collectors
   * @param {*} itemId 
   * @param {*} filters 
   * @param {*} page 
   * @returns 
   */
  getItemCollectors: async (itemId, filters = [], page = 1) => {
    const newFilters = filters.concat('page=' + page);

    var headers = new Headers();
    headers.set('Accept', 'application/ld+json');

    return await api.fetchResource('/api/items/' + itemId + '/collectors.jsonld?' + newFilters.join('&'), {
      method: 'GET',
      headers: headers,
    });
  }
}